import React, { useRef } from "react";
import LoadingSpinner from "../../../../components/widgets/spinner";
import BreadCrumbs from "../../../widgets/bread_crumbs";
import moment from "moment";
import Filters from "../../widgets/filters";
import { useGetAgricultureQuery } from "../../../../redux/slices/agriculture_slice";
import { TAgriculture } from "../../../../redux/types/agriculture.type";
import { TAgroOrder } from "../../../../redux/types/agro_order.type";
import { ErrorWidget } from "../../../utils/error_widget";
import EditAgroStatus from "./edit_agro_status";
import AgroOrdersChart from "./stats";

const AgroTransactions: React.FC = () => {
  const divRef = useRef<HTMLDivElement>(null);
  const queryParams = new URLSearchParams(window.location.search);
  const agro_id = queryParams.get("agro_id");
  const [show, setShow] = React.useState(false);
  const [agroItem, setAgroItem] = React.useState<TAgriculture | any>();
  const handleClose = () => {
    setShow(false);
  };

  const handleShow = (agro: TAgriculture) => {
    setAgroItem(agro);
    setShow(true);
  };
  const [query, setQuery] = React.useState("")
  const [currentPage, setCurrentPage] = React.useState(1);
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setQuery(newValue);
  }
  const {
      data: agro,
      isLoading: isFetching,
      error,
    }: TAgriculture | any = useGetAgricultureQuery(Number(agro_id));
  
    if (isFetching) {
      return (
        <div style={{ marginTop: "20%" }} className="text-center">
          <LoadingSpinner />
        </div>
      );
    }
    if (error) {
      return (
        <ErrorWidget error={error}/>
      );
    }

  return (
    <div className="content">
      <BreadCrumbs
        title={"Agriculture Transactions"}
        subTitle={"View/Manage Agriculture Transactions"}
      />
      <EditAgroStatus
        agro={agroItem}
        show={show}
        setShow={setShow}
        handleClose={handleClose}
      />
      <div className="card">
        <div className="card-body">
        <Filters divRef={divRef} query={query} onSearchChange={onChange} />
        <button onClick={()=> handleShow(agro)} className="btn btn-sm btn-info text-white">
          {agro?.status} <i className="fa fa-edit"></i>
        </button>
        <div className='mb-2'>
            <h3 className="mt-3 mb-2 text-center text-capitalize">
              <span className="text-primary">{agro?.productName}</span>
            </h3>
            <div className="text-center text-capitalize">
            <h4>{agro?.supplier.company_name}</h4>
            </div>
            <AgroOrdersChart transaction_id={agro_id}/>
            <h1>Transactions ({agro?.agri_order_product.length}) </h1>
        </div>
        {agro?.agri_order_product.length === 0 ? <div className="alert alert-info text-center">
            <h3>No Transactions Found</h3>
        </div>:
        <div ref={divRef} className="table-responsive dataview">
            <table className="table datatable">
                <thead>
                    <tr>
                    <th>#</th>
                    <th>Client</th>
                    <th>Product Amount</th>
                    <th>Amount</th>
                    <th>Unit</th>
                    <th>Quantity</th>
                    <th>Received</th>
                    <th>Status</th>
                    <th>Payment</th>
                    <th>Date</th>
                    {/* <th>Action</th> */}
                    </tr>
                </thead>
                <tbody>
                    {agro?.agri_order_product.map((item: TAgroOrder, index: number)=>{
                    return <tr>
                    <td>{index + 1}</td>
                    <td className="productimgname">
                        <a href={`/zm/dashboard/car_details/?car_id=${item.id}`}>{item.customer.first_name}{item.customer.last_name}</a>
                    </td>
                    <td>{Number(agro?.productPrice).toLocaleString()}</td>
                    <td>{Number(item.productPrice).toLocaleString()}</td>
                    <td>{item.unit}</td>
                    <td>{item.quantity}</td>
                    <td>{item.customer_recieved ? "Yes": "No"}</td>
                    <td>
                      <span className={`badges ${item.status !== "COMFIRMED" ? "bg-lightred" : "bg-lightgreen"}`}>
                        {item.status}
                      </span>
                    </td>
                    <td>
                      <span className="badges bg-lightgreen">
                        Paid
                      </span>
                    </td>
                    <td>{moment(item.created_at).format("Do MMM YYYY")}</td>
                    {/* <td>
                      <a href={`/zm/dashboard/car_details/?car_id=${item.id}`}><i className='fa fa-arrow-right'></i></a>
                    </td> */}
                    </tr>
                    })}
                </tbody>
            </table>
        </div>
        }
        </div>
      </div>
    </div>
  );
};

export default AgroTransactions;
