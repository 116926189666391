import { apiSlice } from "../services/apiSlice";
import { ICarBooking } from "../types/car_booking.type";

export const carBookingAPISlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    hireCar: builder.mutation<
      ICarBooking,
      Partial<ICarBooking>
    >({
      query(reservation) {
        return {
          url: `/cars/v1/everyday/car_booking/tech/`,
          method: "POST",
          body: reservation,
        };
      },
      invalidatesTags: ["ICarBooking"],
      transformResponse: (result: { booking: ICarBooking }) =>
        result.booking,
    }),
    updateBooking: builder.mutation<
      ICarBooking,
      { id: string; booking: ICarBooking }
    >({
      query: ({ id, booking }) => ({
        url: `/cars/v1/everyday/car_booking/tech/${id}/`,
        method: "PATCH",
        body: booking,
      }),
      invalidatesTags: ["ICarBooking"],
      transformResponse: (response: { booking: ICarBooking }) =>
        response.booking,
    }),
    getBooking: builder.query<ICarBooking, number>({
      query: (id) => ({
        url: `/cars/v1/everyday/car_booking/tech/${id}/`,
      }),
      providesTags: ["ICarBooking"],
    }),
    getBookings: builder.query<ICarBooking[], any>({
      query: ({car, status}) => `/cars/v1/everyday/car_booking/tech/?status=${status}&car=${car}`,
      transformResponse: (res: ICarBooking[]) =>
        res.sort((a, b) => b.id - a.id),
      providesTags: ["ICarBooking"],
      // keepUnusedDataFor: 5,
    }),
    getCustomerBooking: builder.query<ICarBooking, any>({
      query: ({customer}) => ({
        url: `/cars/v1/everyday/car_booking_customer/${customer}/tech`,
      }),
      providesTags: ["ICarBooking"],
    }),
    getCarCustomers: builder.query<ICarBooking[], any>({
      query: (company) => `/cars/v1/everyday/cars_customer_booking/tech/`,
      transformResponse: (res: ICarBooking[]) =>
        res.sort((a, b) => b.id - a.id),
      providesTags: ["ICarBooking"],
      // keepUnusedDataFor: 5,
    }),
    deleteBooking: builder.mutation<ICarBooking, number>({
      query: (id) => ({
        url: `/cars/v1/everyday/car_booking/tech/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["ICarBooking"],
    }),
  }),
});
export const {
useDeleteBookingMutation,
useGetBookingQuery,
useGetBookingsQuery,
useUpdateBookingMutation,
useGetCustomerBookingQuery,
useGetCarCustomersQuery,
useHireCarMutation,
} = carBookingAPISlice;
