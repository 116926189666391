import React from "react";
import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import httpDashboardServices from "../../services/http.cars.services";
import { DragAndDrop } from "../../../widgets/DragNdrop";
import { useGetBrandsQuery } from "../../../../redux/slices/car_brands_apislice";
import { ICarBrand } from "../../../../redux/types/car_rental.type";
import { useForm } from "react-hook-form";

interface ICreate {
  user_id: any;
}

export type DataFormFields = {
  user: number;
  seats: number;
  speedometer: number;
  fuel_litres: number;
  white_book: File;
  insurance: File;
  fitness: File;
  description: string;
  music_types: string;
  number_plate: string;
  car_model: string;
  car_brand: number;
  local_price: number;
  outside_town: number;
  gear_type: string,
};

const CreateCar: React.FC<ICreate> = ({ user_id }) => {
  const [files, setFiles] = React.useState<string[]>([]);
  const [whiteBookImage, setWhiteBook] = React.useState<File | any>();
  const [insuranceImage, setInsurance] = React.useState<File | any>();
  const [fitnessImage, setFitness] = React.useState<File | any>();
  const [formSubmitting, setFormSubitting] = React.useState(false);
  const {
    data: carBrands,
    isLoading: fetchingBrands,
    error,
  }: ICarBrand | any = useGetBrandsQuery({searchQuery: ""});
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<DataFormFields>();

  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const uploadImages = (car: any) => {
    setFormSubitting(true);
    const bodyFormData = new FormData();
    files.map(async (image, index) => {
      bodyFormData.append("car", car);
      bodyFormData.append("image", image);
      try {
        httpDashboardServices
          .uploadProductImages(bodyFormData)
          .then((response) => {
            setShow(false);
            // reset();
            toast.success(`Uploaded Car image ${index + 1}`);
          })
          .catch((error) => {
            console.error(`UPLOAD IMAGE FAILED${error}`);
            // toast.error(error.message);
            setFormSubitting(false);
          });
      } catch (error) {}
    });
  };

  // add travel
  const onSubmit = handleSubmit(async (data) => {
    setFormSubitting(true);
    await httpDashboardServices.createCarRental({
        user: user_id,
        fitness: fitnessImage,
        insurance: insuranceImage,
        white_book: whiteBookImage,
        seats: data.seats,
        speedometer: data.speedometer,
        fuel_litres: data.fuel_litres,
        music_type: data.fuel_litres,
        number_plate: data.number_plate,
        car_model: data.car_model,
        car_brand: data.car_brand,
        local_price: data.local_price,
        outside_town: data.outside_town,
        gear_type: data.gear_type,
        description: data.description,
      }).then((response: any) => {
        uploadImages(response.data.id);
        toast.success("Car Added Successfully");
      }).catch((err) => {
        toast.error(`Error: ${err.message}`);
        setFormSubitting(false);
      });
  });
  return (
    <>
      <div className="mt-4 text-center">
        <button onClick={handleShow} className="btn btn-sm btn-primary">
          <i className="fa fa-plus"></i> Add New Rental Car
        </button>
      </div>
      <Modal
        size="xl"
        show={show}
        scrollable
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add New Car Rental</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            id="paypal_donate_form_onetime_recurring"
            onSubmit={onSubmit}
          >
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-sm-12 col-md-3">
                    <div className="form-group">
                      <label htmlFor="form_name">
                        Car Seats <small className="text-danger">*</small>
                      </label>
                      <input
                        id="seats"
                        disabled={formSubmitting}
                        {...register("seats", { required: true })}
                        className={`form-control ${
                          errors.seats ? "border-danger" : ""
                        }`}
                        name="seats"
                        type="number"
                        placeholder="Number of Seats"
                      />
                      {errors.seats && (
                        <p className="text-danger">This field is required.</p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-3">
                    <div className="form-group">
                      <label htmlFor="form_name">
                        Speedometer <small className="text-danger">*</small>
                      </label>
                      <input
                        id="speedometer"
                        disabled={formSubmitting}
                        {...register("speedometer", { required: true })}
                        className={`form-control ${
                          errors.speedometer ? "border-danger" : ""
                        }`}
                        name="speedometer"
                        type="number"
                        placeholder="Speedometer"
                      />
                      {errors.speedometer && (
                        <p className="text-danger">This field is required.</p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-3">
                    <div className="form-group">
                      <label htmlFor="form_name">
                        Fuel Tank <small className="text-danger">*</small>
                      </label>
                      <input
                        id="fuel_litres"
                        disabled={formSubmitting}
                        {...register("fuel_litres", { required: true })}
                        className={`form-control ${
                          errors.fuel_litres ? "border-danger" : ""
                        }`}
                        name="fuel_litres"
                        type="number"
                        step="0.01"
                        placeholder="eg 5.6"
                      />
                      {errors.fuel_litres && (
                        <p className="text-danger">This field is required.</p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-3">
                    <div className="form-group">
                      <label htmlFor="form_name">
                        Music <small className="text-danger">*</small>
                      </label>
                      <input
                        id="music_types"
                        disabled={formSubmitting}
                        {...register("music_types", { required: true })}
                        className={`form-control ${
                          errors.music_types ? "border-danger" : ""
                        }`}
                        name="music_types"
                        type="text"
                        placeholder="E.G Radiio/Bluetooth/USB"
                      />
                      {errors.music_types && (
                        <p className="text-danger">This field is required.</p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4">
                    <div className="form-group">
                      <label htmlFor="form_name">
                        Number Plate
                        <small className="text-danger">*</small>
                      </label>
                      <input
                        id="title"
                        disabled={formSubmitting}
                        {...register("number_plate", { required: true })}
                        className={`form-control ${
                          errors.number_plate ? "border-danger" : ""
                        }`}
                        name="number_plate"
                        type="text"
                        placeholder="Car Number Plate"
                      />
                      {errors.number_plate && (
                        <p className="text-danger">This field is required.</p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4">
                    <div className="form-group">
                      <label htmlFor="form_name">
                        Car Model <small className="text-danger">*</small>
                      </label>
                      <input
                        id="title"
                        disabled={formSubmitting}
                        {...register("car_model", { required: true })}
                        className={`form-control ${
                          errors.car_model ? "border-danger" : ""
                        }`}
                        name="car_model"
                        type="text"
                        placeholder="Car Model e.g BMW X7,TOYOTA FORTUNER 2021"
                      />
                      {errors.car_model && (
                        <p className="text-danger">This field is required.</p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4">
                    <div className="form-group">
                      <label htmlFor="form_email">
                        Car Brand <small className="text-danger">*</small>
                      </label>
                      <select
                        disabled={formSubmitting}
                        {...register("car_brand", { required: true })}
                        className={`form-control text-capitalize ${
                          errors.car_brand ? "border-danger" : ""
                        }`}
                        name="car_brand"
                      >
                        <option value="">Choose Car Brand</option>
                        {fetchingBrands ? (
                          <div></div>
                        ) : error ? (
                          <></>
                        ) : (
                          carBrands.map((item: ICarBrand, index: number) => {
                            return (
                              <option key={index} value={item.id}>
                                {item.brand_name}
                              </option>
                            );
                          })
                        )}
                      </select>
                      {errors.car_brand && (
                        <p className="text-danger">This field is required.</p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4">
                    <div className="form-group">
                      <label htmlFor="form_email">
                        Local Car Rental Price{" "}
                        <small className="text-danger">*</small>
                      </label>
                      <input
                        id="form_email"
                        disabled={formSubmitting}
                        {...register("local_price", { required: true })}
                        className={`form-control ${
                          errors.local_price ? "border-danger" : ""
                        }`}
                        name="local_price"
                        type="text"
                        placeholder="Local Car Rental Price Per DAY"
                      />
                      {errors.local_price && (
                        <p className="text-danger">This field is required.</p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4">
                    <div className="form-group">
                      <label htmlFor="form_email">
                        OutSide Local Car Rental Price{" "}
                        <small className="text-danger">*</small>
                      </label>
                      <input
                        id="form_email"
                        disabled={formSubmitting}
                        {...register("outside_town", { required: true })}
                        className={`form-control ${
                          errors.outside_town ? "border-danger" : ""
                        }`}
                        name="outside_town"
                        type="text"
                        placeholder="Outside Local Car Rental Price Per DAY"
                      />
                      {errors.outside_town && (
                        <p className="text-danger">This field is required.</p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4">
                    <div className="form-group">
                      <label htmlFor="form_email">
                        White Book (Image){" "}
                        <small className="text-danger">*</small>
                      </label>
                      <input
                        id="form_email"
                        accept="image/*"
                        disabled={formSubmitting}
                        {...register("white_book", {
                          required: true,
                          onChange: (
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            if (event.target.files && event.target.files[0]) {
                              setWhiteBook(event.target.files[0]);
                            }
                          },
                        })}
                        className={`form-control ${
                          errors.white_book ? "border-danger" : ""
                        }`}
                        name="white_book"
                        type="file"
                        placeholder="White Book (Image)"
                      />
                      {errors.white_book && (
                        <p className="text-danger">This field is required.</p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4">
                    <div className="form-group">
                      <label htmlFor="form_email">
                        Insurance (Image){" "}
                        <small className="text-danger">*</small>
                      </label>
                      <input
                        id="form_email"
                        disabled={formSubmitting}
                        accept="image/*"
                        {...register("insurance", {
                          required: true,
                          onChange: (
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            if (event.target.files && event.target.files[0]) {
                              setInsurance(event.target.files[0]);
                            }
                          },
                        })}
                        className={`form-control ${
                          errors.insurance ? "border-danger" : ""
                        }`}
                        name="insurance"
                        type="file"
                        placeholder="Insurance (Image)"
                      />
                      {errors.insurance && (
                        <p className="text-danger">This field is required.</p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4">
                    <div className="form-group">
                      <label htmlFor="form_email">
                        Fitness (Image) <small className="text-danger">*</small>
                      </label>
                      <input
                        id="form_email"
                        disabled={formSubmitting}
                        accept="image/*"
                        {...register("fitness", {
                          required: true,
                          onChange: (
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            if (event.target.files && event.target.files[0]) {
                              setFitness(event.target.files[0]);
                            }
                          },
                        })}
                        className={`form-control ${
                          errors.fitness ? "border-danger" : ""
                        }`}
                        name="fitness"
                        type="file"
                        placeholder="Fitness (Image)"
                      />
                      {errors.fitness && (
                        <p className="text-danger">This field is required.</p>
                      )}
                    </div>
                  </div>

                  <div className="col-sm-12 col-md-4">
                    <div className="form-group">
                      <label htmlFor="form_name">
                        Gear Box
                        <small className="text-danger">*</small>
                      </label>
                      <select
                        disabled={formSubmitting}
                        {...register("gear_type", { required: true })}
                        className={`form-control ${
                          errors.gear_type ? "border-danger" : ""
                        }`}
                        name="gear_type"
                      >
                        <option value="">Choose Transmission Type</option>
                        <option value="automatic">
                          Automatic Transmission
                        </option>
                        <option value="manual">Manual Transmission</option>
                      </select>
                      {errors.gear_type && (
                        <p className="text-danger">This field is required.</p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label htmlFor="form_name">
                        Car Description <small className="text-danger">*</small>
                      </label>
                      <textarea
                        rows={5}
                        cols={8}
                        id="description"
                        disabled={formSubmitting}
                        {...register("description", { required: true })}
                        className={`form-control ${
                          errors.description ? "border-danger" : ""
                        }`}
                        minLength={200}
                        name="description"
                        placeholder="Car Description"
                      ></textarea>
                      {errors.description && (
                        <p className="text-danger">This field is required.</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <DragAndDrop
                      heading={"Car Image Gallery"}
                      onChange={(e: any) => {
                        if (e.target.files && e.target.files[0]) {
                          for (let i = 0; i < e.target.files["length"]; i++) {
                            setFiles((prevState: any) => [
                              ...prevState,
                              e.target.files[i],
                            ]);
                          }
                        }
                      }}
                      setFiles={setFiles}
                      files={files}
                    />
                  </div>
                </div>
              </div>

              <Modal.Footer>
                <button
                  type="submit"
                  className="btn btn-success"
                  disabled={formSubmitting || files.length < 3}
                >
                  {formSubmitting && <i className="fa fa-refresh fa-spin" />}{" "}
                  {files.length < 3 ? "Please select 3 Images" : "Submit"}
                </button>
                <div className="col-md-5">
                  <Button
                    disabled={formSubmitting}
                    variant="secondary"
                    onClick={handleClose}
                  >
                    Close
                  </Button>
                </div>
              </Modal.Footer>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CreateCar;
