import React from 'react'
import BreadCrumbs from '../../widgets/bread_crumbs'

const Customers: React.FC = () => {
  return (
    <div className='content'>
        <BreadCrumbs
        title={"Customers"}
        subTitle={"Manage Your Customers"}
      />
      <center>
        <div className="alet alert-info">
            <i className='fa fa-users fa-5x'></i>
            <h3><b>No Customers Found</b></h3>
        </div>
      </center>
    </div>
  )
}

export default Customers