import React, { useState } from "react";
import { PropertyModel } from "../../../../models/product_model";
import ProductDataService from "../../../services/http.product.services";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

interface PaymentProps {
  property: PropertyModel | any;
  fetchData: () => void;
}

function DeletePayment({ property, fetchData }: PaymentProps) {
  const [deleting, setDeleting] = useState<boolean>(false);


  const {
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data: any) => {
    setDeleting(true);
    ProductDataService.deletePropery(property?.id)
      .then((response) => {
        fetchData();
        toast.success("Property Deleted Successfully");
        setDeleting(false);
        reset();
        window.location.reload();
      })
      .catch((error) => {
        setDeleting(false);
        toast.error(error);
      });
     
    
  };
  return (
    <>
      {/* <!-- Bid Offer Modal --> */}
      <div
        className="modal fade"
        id="deleteModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="deleteModal"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered offer-pop-form"
          role="document"
        >
          <div className="modal-content" id="deleteModal">
            <span
              className="mod-close"
              data-bs-dismiss="modal"
              aria-hidden="true"
            >
              <i className="fas fa-close"></i>
            </span>
            <div className="modal-body">
              <div className="text-center">
                
                <h2 className="mb-5 capitalize text-danger">
                  Delete {property?.propertyName}{" "}
                </h2>
                
              </div>
              <div className="offer-form">
                <form onSubmit={handleSubmit(onSubmit)}>
                 <h3 className="capitalize mb-5 mt-5">Are you sure you want to Delete {property?.propertyName}? </h3>
                  <div className="form-group">
                    <button
                      disabled={deleting}
                      type="submit"
                      className="btn full-width btn-primary"
                    >
                      {deleting && (
                        <span>
                          <i className="fa fa-refresh fa-spin"></i> Please Wait
                          ...
                        </span>
                      )}
                      {!deleting && <span> <i className="fa fa-trash"></i> Delete</span>}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End Modal --> */}
    </>
  );
}

export default DeletePayment;
