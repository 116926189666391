import React from "react";
import { useAppSelector } from "../../../../hooks/redux-hooks";
import { ICarBooking } from "../../../../redux/types/car_booking.type";
import ReservationCard from "./reservation_card";
import moment from "moment";
import { useGetAdminCustomerBookingsQuery } from "../../../../redux/slices/admin_hire_slice";

const CarReservation: React.FC = () => {
  const user = useAppSelector((state) => state.auth.user);
  const [number, setNumber] = React.useState(1); // No of pages
  const [bookingPerPage] = React.useState(20);
  const [searchValue, setQuery] = React.useState("");
  const [searchParam] = React.useState([
    "first_name",
    "last_name",
    "email",
    "phone_number",
    "car_model",
    "car_id",
  ]);
  const [filterParam, setFilterParam] = React.useState("All");
  const [status, setStatus] = React.useState<string>("");
  const {
    data: bookings,
    isLoading,
    isFetching,
    error,
  }: ICarBooking | any = useGetAdminCustomerBookingsQuery({
    company: user?.id,
    status: status,
  });

  if (isLoading || isFetching) {
    return (
      <div className="text-center mt-5">
        <i className="fa fa-spinner fa-spin fa-5x text-danger"></i>
      </div>
    );
  }

  if (error) {
    return (
      <div className="content">
        <center>
        <div className="alert alert-danger text-center mt-5" role="alert">
          <h4>
            <b>An Error Occurred</b>
          </h4>
          {error.status}
        </div>
      </center>
      </div>
    );
  }



  // pagination
  const lastData = number * bookingPerPage;
  const firstData = lastData - bookingPerPage;
  const currentData = bookings.slice(firstData, lastData);
  const pageNumber = [];

  for (let i = 1; i <= Math.ceil(bookings.length / bookingPerPage); i++) {
    pageNumber.push(i);
  }

  const ChangePage = (pageNumber: number) => {
    setNumber(pageNumber);
  };

  const data = Object.values(currentData);

  function filterData(list: any) {
    return list.filter((item: any) => {
      if (item.client.first_name === filterParam) {
        return searchParam.some((newItem) => {
          return (
            item[newItem]
              .toString()
              .toLowerCase()
              .indexOf(searchValue.toLowerCase()) > -1
          );
        });
      } else if (item.client.last_name === filterParam) {
        return searchParam.some((newItem) => {
          return (
            item[newItem]
              .toString()
              .toLowerCase()
              .indexOf(searchValue.toLowerCase()) > -1
          );
        });
      } else if (item.client.email === filterParam) {
        return searchParam.some((newItem) => {
          return (
            item[newItem]
              .toString()
              .toLowerCase()
              .indexOf(searchValue.toLowerCase()) > -1
          );
        });
      } else if (item.car.car_model === filterParam) {
        return searchParam.some((newItem) => {
          return (
            item[newItem]
              .toString()
              .toLowerCase()
              .indexOf(searchValue.toLowerCase()) > -1
          );
        });
      } else if (item.phone_number === filterParam) {
        return searchParam.some((newItem) => {
          return (
            item[newItem]
              .toString()
              .toLowerCase()
              .indexOf(searchValue.toLowerCase()) > -1
          );
        });
      } else if (item.car_id === filterParam) {
        return searchParam.some((newItem) => {
          return (
            item[newItem]
              .toString()
              .toLowerCase()
              .indexOf(searchValue.toLowerCase()) > -1
          );
        });
      } else if (item.status === filterParam) {
        return searchParam.some((newItem) => {
          return (
            item[newItem]
              .toString()
              .toLowerCase()
              .indexOf(searchValue.toLowerCase()) > -1
          );
        });
      } else if (filterParam === "All") {
        return searchParam.some((newItem) => {
          return (
            item[newItem]
              .toString()
              .toLowerCase()
              .indexOf(searchValue.toLowerCase()) > -1
          );
        });
      }
    });
  }

  return (
    <>
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Car Rentals List</h4>
            <h6>Manage your Car Rentals</h6>
          </div>
        </div>
      <div className="row">
        <div className="col-md-4">
          <label htmlFor="search-form">Search</label>
          <div className="form-group">
            <input
              type="search"
              name="search-form"
              id="search-form"
              className="form-control"
              placeholder="Search for..."
              value={searchValue}
              onChange={(e) => setQuery(e.target.value)}
            />
          </div>
        </div>
        <div className="col-md-4">
          <label htmlFor="search-form">Search By Date</label>
          <div className="form-group">
            <select
              className="form-control"
              name="search-form"
              id="search-form"
              onChange={(e) => setFilterParam(e.target.value)}
            >
              <option value="All" selected>
                All
              </option>
              {error ? <></>: bookings.map((item: ICarBooking, index: number) => (
                <option key={index} value={item.pick_up_date.toString()}>
                  {moment(item.pick_up_date).format("MMMM Do, YYYY")}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-md-4">
          <label htmlFor="search-form">Search By Status</label>
          <div className="form-group">
            <select
              className="form-control"
              name="search-form"
              id="search-form"
              onChange={(e) => setFilterParam(e.target.value)}
            >
              <option value="All" selected>
                All
              </option>
              <option value="PENDING">Pending</option>
              <option value="CONFIRMED">Confirmed</option>
              <option value="CANCELLED">Cancelled</option>
            </select>
          </div>
        </div>
      </div>
      {error ? <div className="content">
        <center>
        <div className="alert alert-danger text-center mt-5" role="alert">
          <h4>
            <b>An Error Occurred</b>
          </h4>
          {error.status}
        </div>
      </center>
      </div> : bookings.length === 0 ? (
        <center>
          <div
            className="text-center alert alert-info custom-length"
            role="alert"
          >
            <i className="fa fa-car fa-5x"></i>
            <h1>NO RENTALS FOUND</h1>
          </div>
        </center>
      ) : (
        <div className="table-responsive">
          <table className="table datanew">
            <thead>
              <tr>
                <th>#</th>
                <th>Pick Up Date</th>
                <th>Customer Name</th>
                <th>Reference</th>
                <th>Status</th>
                <th>Payment</th>
                <th>Total</th>
                <th>Paid</th>
                <th className="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {bookings.map((item: ICarBooking, index: number) => {
                return (
                  <ReservationCard item={item} index={index} key={index} />
                );
              })}
            </tbody>
          </table>
          {bookings.length < 8 ? (
            <div></div>
          ) : (
            <div className="row mt-5">
              <div className="col text-center">
                <div className="block-27">
                  <ul>
                    <li>
                      <button
                        disabled={number === 1}
                        onClick={() => setNumber(number - 1)}
                      >
                        &lt;
                      </button>
                    </li>
                    {pageNumber.map((page, index) => (
                      <li
                        key={index}
                        className={`${number === page ? "active" : ""}`}
                      >
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() => ChangePage(page)}
                        >
                          <span>{page}</span>
                        </a>
                      </li>
                    ))}

                    <li>
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={() => setNumber(number + 1)}
                      >
                        &gt;
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      </div>
    </>
  );
};

export default CarReservation;
