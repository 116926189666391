import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ICarBooking } from "../../../../redux/types/car_booking.type";
import { toast } from "react-toastify";
import { useUpdateBookingMutation } from "../../../../redux/slices/car_hire_slice";
import moment from "moment";
import { useForm } from "react-hook-form";

interface ITransaction {
  item: ICarBooking;
  show: boolean;
  handleClose: () => void;
  // handleShow: (booking: ICarBooking) => void;
}

const TransactionModalDetails: React.FC<ITransaction> = ({
  item,
  show,
  handleClose,
}) => {
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
      } = useForm();
      
  const [updateBooking, { isLoading }] = useUpdateBookingMutation();

  const onSubmit = async (data: any) => {
    updateBooking({id: item.id, ...data})
      .unwrap()
      .then(() => {
        toast.success("Booking updated Successfully");
        reset();
        handleClose();
      })
      .catch((err) => {
        console.error(err.error);
        toast.error("Failed to add:" + err.error);
      });
  };

  // let returnDate = new Date(item.client_pickup_date_time);

  // returnDate.setDate(returnDate.getDate() + item.days);
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        scrollable
        size="xl"
        centered={true}
        keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title className="text-capitalize">
            {item.car.car_model}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <div className="row">
              <div className="col-md-4 shadow rounded">
                <h4 className="text-center">Car Details</h4>
                <h6 className="text-capitalize">Car: {item.car.car_model}</h6>
                <h6>Number Plate: {item.car.number_plate?.toLocaleUpperCase()}</h6>
                <h6 className="text-capitalize">
                  Transmission: {item.car.gear_type}
                </h6>
                <h6>Seats: {item.car.seats}</h6>
                <h6>Speedometer: {item.car.speedometer}</h6>
                <h6>Fuel Tank Litres: {item.car.fuel_litres}L</h6>
                <h6>
                  Local Rental Price: ZMW {item.car.local_price.toLocaleString()} Per
                  Day
                </h6>
                <h6>
                  Outside Town Rental Price: ZMW {item.car.outside_town.toLocaleString()} Per
                  Day
                </h6>
                STATUS:
                {item.car.is_available ? (
                  <span className="badges bg-lightgreen text-white">
                    AVAILABLE
                  </span>
                ) : (
                  <span className="badges bg-lightred text-white">
                    NOT AVAILABLE
                  </span>
                )}
              </div>
              <div className="col-md-8 shadow rounded">
                <h4 className="text-center mb-4">Client Details</h4>
                <h6 className="text-capitalize">
                  Name:{" "}
                  <b>
                    {item.client.first_name} {item.client.last_name}{" "}
                  </b>
                </h6>
                <h6>
                  E-mail: <b>{item.client.email}</b>
                </h6>
                <h6>Phone Number: {item.phone_number}</h6>
                <h4 className="text-center mb-4 mt-4">Booking Details</h4>
                <div className="row">
                  <div className="col-md-6">
                    <h6>Paid: ZMW{item.rental_price.toLocaleString()}</h6>
                    <h6>Days: {item.days}</h6>
                  {/* <div className="row">
                    <div className="col-md-6">
                      <h5>Client NRC</h5>
                      <img src={item.client_nrc} alt="nrc" />
                    </div>
                    <div className="col-md-6">
                      <h5>Driving License</h5>
                      <img src={item.client_license} alt="license" />
                    </div>
                  </div> */}
                <h6 className="mt-5">
                  Status:{" "}
                  <span
                    className={`badges ${
                      item.status === "PENDING"
                        ? "bg-lightred"
                        : "bg-lightgreen"
                    } text-white`}>
                    {item.status}
                  </span>{" "}
                </h6>
                  </div>
                  <div className="col-md-6">
                      <div className="row mb-5">
                        <div className="col-md-6">
                          <h6>Pick Up Date</h6>
                          <small className="text-capitalize">{moment(item.client_pickup_date_time).format("Do MMM YYYY HH:mm a")}</small>
                        </div>
                        <div className="col-md-6">
                          <h6>Return Date</h6>
                          <small className="text-capitalize">{moment(item.client_return_date_time).format("Do MMM YYYY HH:mm a")}</small>
                        </div>
                        <div className="col-md-6">
                          <h6>Pick Up Location</h6>
                          <small className="text-capitalize">{item.client_pickup_location}</small>
                        </div>
                        <div className="col-md-6">
                          <h6>Return Location</h6>
                          <small className="text-capitalize">{item.client_return_location}</small>
                        </div>
                      </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="form-group">
                        <label htmlFor="status">Booking Status</label>
                        <select
                          {...register("status", { required: true })}
                          name="status"
                          id="status"
                          className={`form-control ${
                            errors.status ? "border-danger" : ""
                          }`}>
                          <option value="">Select Status</option>
                          <option value="PENDING">Pending</option>
                          <option value="CONFIRMED">Confirmed</option>
                          <option value="CANCELLED">Cancelled</option>
                        </select>
                        {errors.status && (
                      <p className="text-danger">This field is required.</p>
                    )}
                      </div>
                      <Button
                        disabled={isLoading}
                        type="submit"
                        variant="primary">
                        {isLoading && (
                          <i className="fa fa-spinner fa-spin text-danger"></i>
                        )}{" "}
                        <i className="fa fa-upload"></i> Submit
                      </Button>
                    </form>
                  </div>
                </div>

                
              </div>
            </div>
            <Modal.Footer>
              <Button
                disabled={isLoading}
                variant="secondary"
                onClick={handleClose}>
                Close
              </Button>
              <Button disabled={isLoading} type="submit" variant="primary">
                {isLoading && (
                  <i className="fa fa-spinner fa-spin text-danger"></i>
                )}{" "}
                <i className="fa fa-print"></i> Print
              </Button>
            </Modal.Footer>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TransactionModalDetails;
