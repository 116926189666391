import React from "react";
import moment from "moment";
import { PropertyModel } from "../../../models/product_model";
import DataService from "../../services/http.product.services";
import PaymentModal from "./widgets/paymentModal";
import DeletePayment from "./widgets/deleteModal";
import FilterProperties from "./filter";
import { useAppSelector } from "../../../hooks/redux-hooks";

function Properties() {
  const user = useAppSelector((state) => state.auth.user);
  const [properties, setProperties] = React.useState<PropertyModel[]>([]);
  const [property, setProperty] = React.useState<PropertyModel>();
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [selected, setSelected] = React.useState<string>("all")

  const details = (property: PropertyModel) => {
    setProperty(property);
  };

  const fetchProperties = async () => {
    setIsLoading(true);
    DataService.getProperties(user?.id)
      .then((response) => {
        setProperties(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setIsLoading(false);
      });
  };

  React.useEffect(() => {
    fetchProperties();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const filter= (value: string) => {
      setSelected(value);
    
  }

  return (
    <div className="content">
      {/* <!-- ============================ User Dashboard ================================== --> */}
      <section className="gray pt-0 pb-5 content">
        <div className="col-xl-12 col-lg-12 col-md-12">
          <div className="dashboard-body">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="_prt_filt_dash">
                  {/* filter here */}
                  <FilterProperties value={selected} filter={filter} />
                  <div className="form-group col-md-3 col-md-6 col-xs-6 m-2">
                    <input type="text" className="form-control" placeholder="search for properties" />
                  </div>
                  <div className="_prt_filt_dash_last m2_hide">
                    <div className="_prt_filt_radius"></div>
                    <div className="_prt_filt_add_new">
                      <a
                        href="/zm/accounts/realestate/add-property"
                        className="prt_submit_link">
                        <i className="fas fa-plus-circle"></i>
                        <span className="d-none d-lg-block d-md-block">
                          List New Property
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="alert alert-info text-center" role="alert">
              <h4>
                Properties with{" "}
                <span className="text-warning">Pending Status</span> are not
                Listed until payments are made for them
              </h4>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="dashboard_property">
                  <div className="table-responsive">
                    {isLoading ? (
                      <div className="text-center">
                        <i className="fa fa-spinner fa-spin fa-5x text-danger"></i>
                      </div>
                    ) : error !== null ? (
                      <div
                        className="alert alert-danger text-center"
                        role="alert">
                        <i className="fa fa-warning fa-5x"></i>
                        <div className="mt-3">{error}</div>
                      </div>
                    ) : properties.length === 0 ? (
                      <div className="alert alert-info text-center">
                        <i className="fa-solid fa-house-fire fa-10x"></i>
                        <h3>
                          You haven't added any Listing, click{" "}
                          <a
                            className="btn btn-sm btn-primary"
                            href="/zm/accounts/realestate/add-property">
                            <i className="fa fa-plus-circle"></i> HERE
                          </a>{" "}
                          to add Your listings
                        </h3>
                      </div>
                    ) : (
                      <table className="table table-hover">
                        <thead className="thead-dark">
                          <tr>
                            <th scope="col">Property</th>

                            <th scope="col" className="m2_hide">
                              Stats
                            </th>
                            <th scope="col" className="m2_hide">
                              Posted On
                            </th>
                            <th scope="col">Listing Status</th>
                            <th scope="col">Property Status</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* <!-- tr block --> */}
                          {properties.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  <div className="dash_prt_wrap">
                                    <div className="dash_prt_thumb">
                                      <img
                                        src={`${process.env.REACT_APP_API_URL}${item.image}`}
                                        className="img-fluid"
                                        alt=""
                                      />
                                    </div>
                                    <div className="dash_prt_caption">
                                      <h5 className="capitalize">
                                        {item.propertyName}
                                      </h5>
                                      <div className="prt_dashb_lot capitalize">
                                        {item.propertyAddress}
                                      </div>
                                      <div className="prt_dash_rate">
                                        <span>
                                          ZMW {`${Number(item.propertyPrice).toLocaleString()}${item.propertyLabel === "rent" ? "/Month" : ""}`}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </td>

                                <td className="m2_hide">
                                  <div className="_leads_view">
                                    <h5 className="up">
                                      {Number(item.propertyReviews).toLocaleString()}
                                    </h5>
                                  </div>
                                  <div className="_leads_view_title">
                                    <span>Total Views</span>
                                  </div>
                                </td>
                                <td className="m2_hide">
                                  <div className="_leads_posted">
                                    <h5>
                                      {moment(item.created_at).format( "Do MMM YYYY - HH:MM a")}
                                    </h5>
                                  </div>
                                  <div className="_leads_view_title">
                                    <span>
                                      {moment(item.created_at).fromNow()}
                                    </span>
                                  </div>
                                </td>

                                <td>
                                  <div className="_leads_status text-capitalize">
                                    <span
                                      className={`text-capitalize ${item.status === "PENDING" ? "expire" : "active"}`}>
                                      {item.status}
                                    </span>
                                  </div>
                                </td>
                                <td className="text-capitalize">
                                  {item.rentedOrSold && item.propertyLabel === "rent" ? (
                                    <span>
                                      <i className="fa fa-home"></i> Property
                                      Rented
                                    </span>
                                  ) : item.rentedOrSold &&
                                    item.propertyLabel === "sale" ? (
                                    <span>
                                      <i className="fa fa-home"></i> Property
                                      Sold
                                    </span>
                                  ) : (
                                    `for ${item.propertyLabel}`
                                  )}
                                </td>
                                <td>
                                  {item.rentedOrSold ? (
                                    <div className="_leads_action">
                                      No Action
                                    </div>
                                  ) : (
                                    <div className="_leads_action">
                                      <a href={`/zm/accounts/realestate/edit-property/?property=${item.id}`}>
                                        <i className="fas fa-edit"></i>
                                      </a>
                                      <a
                                        onClick={() => details(item)}
                                        className="bg-danger text-white"
                                        data-bs-toggle="modal"
                                        data-bs-target="#deleteModal"
                                        href="#">
                                        <i className="fas fa-trash"></i>
                                      </a>
                                      {item.status === "PENDING" && (
                                        <a
                                          onClick={() => details(item)}
                                          data-bs-toggle="modal"
                                          data-bs-target="#paymentModal"
                                          href="#">
                                          <i className="fas fa-dollar-sign"></i>
                                        </a>
                                      )}
                                    </div>
                                  )}
                                </td>
                                <PaymentModal
                                  property={property === null ? item : property}
                                  fetchData={fetchProperties}
                                />
                                <DeletePayment
                                  property={property === null ? item : property}
                                  fetchData={fetchProperties}
                                />
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- row --> */}
          </div>
        </div>
      </section>
      {/* <!-- ============================ User Dashboard End ================================== --> */}
    </div>
  );
}

export default Properties;
