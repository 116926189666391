import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import httpProductServices from "../../services/http.product.services";
import floorElevationImg from "../../../img/fl-6.jpeg";
import { toast } from "react-toastify";
import { Utilities } from "../../../redux/types/utilities.type";
import { useAppSelector } from "../../../hooks/redux-hooks";
import { DragAndDrop } from "../../widgets/DragNdrop";

const HouseForm: React.FC = () => {
  const user = useAppSelector((state) => state.auth.user);
  const [error, setError] = useState<string>("");
  const [files, setFiles] = React.useState<string[]>([]);
  const validateFile = (file: File | null) => {
    if (file) {
      if (file.type.startsWith("image/")) {
        setError("Please select an image file");
      } else if (file.size > 1000000) {
        setError("File size is too large");
      } else {
        setError("");
      }
    }
  };
  const [submiting, setSubmiting] = useState(false);
  const [utilities, setUtilities] = useState<Utilities[]>([
    { name: "" },
    { name: "" },
    { name: "" },
  ]);
  const [amenities, setAmenities] = useState<Utilities[]>([
    { name: "" },
    { name: "" },
    { name: "" },
  ]);
  const [outDoorDetails, setOutDoorDetails] = useState<Utilities[]>([
    { name: "" },
    { name: "" },
    { name: "" },
  ]);
  const [interialDetails, setInterialDetails] = useState<Utilities[]>([
    { name: "" },
    { name: "" },
    { name: "" },
  ]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  // handle Utility input change
  const handleUtilityChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const values = [...utilities];
    values[index].name = e.target.value;
    setUtilities(values);
  };

  // handle click event of the Remove button
  const handleRemoveUtility = (index: number) => {
    const list = [...utilities];
    list.splice(index, 1);
    setUtilities(list);
  };
  // handle click event of the Add button
  const handleAddField = () => {
    const values = [...utilities];
    values.push(...values);
    setUtilities(values);
  };

  // handle OUTDOOR input change
  const handleOutDoorChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: any
  ) => {
    const values = [...outDoorDetails];
    values[index].name = e.target.value;
    setOutDoorDetails(values);
  };

  // handle click event of the Remove button
  const handleRemoveOutDoor = (index: number) => {
    const list = [...outDoorDetails];
    list.splice(index, 1);
    setOutDoorDetails(list);
  };
  // handle click event of the Add button
  const handleAddOutField = () => {
    const values = [...outDoorDetails];
    values.push(...values);
    setOutDoorDetails(values);
  };

  // handle INDOOR input change
  const handleIndoorChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: any
  ) => {
    const values = [...interialDetails];
    values[index].name = e.target.value;
    setInterialDetails(values);
  };

  // handle click event of the Remove button
  const handleRemoveIndoor = (index: number) => {
    const list = [...interialDetails];
    list.splice(index, 1);
    setInterialDetails(list);
  };
  // handle click event of the Add button
  const handleAddIndoorField = () => {
    const values = [...interialDetails];
    values.push(...values);
    setInterialDetails(values);
  };

  // handle AMENITIES input change
  const handleAmenitiesChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: any
  ) => {
    const values = [...amenities];
    values[index].name = e.target.value;
    setAmenities(values);
  };

  // handle click event of the Remove button
  const handleRemoveAmenities = (index: number) => {
    const list = [...amenities];
    list.splice(index, 1);
    setAmenities(list);
  };
  // handle click event of the Add button
  const handleAddAmenitiesField = () => {
    const values = [...amenities];
    values.push(...values);
    setAmenities(values);
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const uploadPropertyImages = (propertyID: any) => {
    const bodyFormData = new FormData();
    files.map(async (image: any) => {
      bodyFormData.append("property", propertyID);
      bodyFormData.append("image", image);
      try {
        httpProductServices
          .submitPropertyImages(bodyFormData)
          .then((response) => {
            if (response.status === 201) {
              // reset();
              // window.location.reload();
              // toast.success(response.data.message);
            } else {
              // toast.error(response.data.message);
            }
          })
          .catch((error) => {
            console.error(error);
            // toast.error(error.message);
          });
      } catch (error) {}
    });
  };

  const handleFormSubmit = async (data: any): Promise<void> => {
    const uid: any = user?.id;
    let form_data = new FormData();
    form_data.append("user", uid);
    form_data.append("propertyPrice", data.propertyPrice);
    form_data.append("propertyDescription", data.propertyDescription);
    form_data.append("contactName", data.contactName);
    form_data.append("contactEmail", data.contactEmail);
    form_data.append("phoneNumber", data.phoneNumber);
    form_data.append("propertyName", data.propertyName);
    form_data.append("propertyLabel", data.propertyLabel);
    form_data.append("propertyLocation", data.propertyAddress);
    form_data.append("propertyType", data.propertyType);
    form_data.append("yearBuilt", data.yearBuilt);
    form_data.append("bedrooms", data.bedrooms);
    form_data.append("bathrooms", data.bathrooms);
    form_data.append("garages", data.garages);
    form_data.append("propertyCity", data.propertyCity);
    form_data.append("propertyCountry", data.propertyCountry);
    form_data.append("zipCode", data.zipCode);
    form_data.append("squareFeet", data.squareFeet);
    form_data.append("propertyAddress", data.propertyAddress);
    form_data.append("propertyAmenities", JSON.stringify([...amenities]));
    form_data.append("propertyUtilities", JSON.stringify([...utilities]));
    form_data.append("propertyOutdoorDetails", JSON.stringify([...outDoorDetails]));
    form_data.append("propertyInteriorDetails",JSON.stringify([...interialDetails]));
    form_data.append("image", files[0]);

    if (files.length > 4) {
      setSubmiting(true);
      httpProductServices
      .createProperty(form_data)
      .then((response) => {
        if (response.status === 200) {
          uploadPropertyImages(response.data.id);
          setSubmiting(false);
          toast.success("Property Added Successfully");
        }
      })
      .catch((error: any) => {
        setSubmiting(false);
        toast.error(error.message);
      });
    } else {
      toast.error("Please select more than 5 images")
    }
    // reset();
  };

  return (
    <>
      {/* <!-- ============================ User Dashboard ================================== --> */}
      <section className="gray pt-0 pb-5">
        <div className="col-xl-12 col-lg-12 col-md-12">
          <div className="dashboard-body">
            <div className="dashboard-wraper">
            <h3 className="mt-3 mb-3 text-center">House Listing</h3>
              <div className="row">
                {/* <!-- Submit Form --> */}
                <div className="col-lg-12 col-md-12">
                  <form onSubmit={handleSubmit(handleFormSubmit)}>
                    <div className="submit-page">
                      {/* <!-- Basic Information --> */}
                      <div className="frm_submit_block">
                        <h3>Basic Information</h3>
                        <div className="frm_submit_wrap">
                          <div className="row">
                            <div className="form-group col-md-6">
                              <label>
                                Property Title
                                
                              </label>
                              <input
                                type="text"
                                disabled={submiting}
                                placeholder="Property Title"
                                {...register("propertyName", {
                                  required: "Property Name is required!",
                                })}
                                className={`form-control ${
                                  errors.propertyName ? "border-danger" : ""
                                }`}
                              />
                              {errors.propertyName && (
                                <p
                                  className="text-danger"
                                  style={{ fontSize: 14 }}
                                >
                                  {errors.propertyName.message?.toString()}
                                </p>
                              )}
                            </div>

                            <div className="form-group col-md-6">
                              <label>Property Label</label>
                              <select
                                disabled={submiting}
                                {...register("propertyLabel", {
                                  required: "Property Label is required!",
                                })}
                                id="status"
                                className={`form-control ${
                                  errors.propertyLabel ? "border-danger" : ""
                                }`}
                              >
                                <option value="">Select Property Label</option>
                                <option value="rent">For Rent</option>
                                <option value="sale">For Sale</option>
                              </select>
                              {errors.propertyLabel && (
                                <p
                                  className="text-danger"
                                  style={{ fontSize: 14 }}
                                >
                                  {errors.propertyLabel.message?.toString()}
                                </p>
                              )}
                            </div>

                            <div className="form-group col-md-6">
                              <label>Property Type</label>
                              <select
                                disabled={submiting}
                                {...register("propertyType", {
                                  required: "Property Type is required!",
                                })}
                                id="ptypes"
                                className={`form-control ${
                                  errors.propertyType ? "border-danger" : ""
                                }`}
                              >
                                <option value="">Select Property Type</option>
                                <option value="houses">Houses</option>
                                <option value="apartments">Apartment</option>
                                <option value="villas">Villas</option>
                                <option value="commercials">Commercial</option>
                                <option value="offices">Offices</option>
                              </select>
                              {errors.propertyType && (
                                <p
                                  className="text-danger"
                                  style={{ fontSize: 14 }}
                                >
                                  {errors.propertyType.message?.toString()}
                                </p>
                              )}
                            </div>

                            <div className="form-group col-md-6">
                              <label>Price</label>
                              <input
                                type="text"
                                disabled={submiting}
                                {...register("propertyPrice", {
                                  required: "Property Price is required!",
                                })}
                                className={`form-control ${
                                  errors.propertyPrice ? "border-danger" : ""
                                }`}
                                placeholder="ZMW"
                              />
                              {errors.propertyPrice && (
                                <p
                                  className="text-danger"
                                  style={{ fontSize: 14 }}
                                >
                                  {errors.propertyPrice.message?.toString()}
                                </p>
                              )}
                            </div>

                            <div className="form-group col-md-6">
                              <label>Area</label>
                              <input
                                type="text"
                                disabled={submiting}
                                placeholder="Area"
                                {...register("areaLocation", {
                                  required: "Property Area is required!",
                                })}
                                className={`form-control ${
                                  errors.areaLocation ? "border-danger" : ""
                                }`}
                              />
                              {errors.areaLocation && (
                                <p
                                  className="text-danger"
                                  style={{ fontSize: 14 }}
                                >
                                  {errors.areaLocation.message?.toString()}
                                </p>
                              )}
                            </div>

                            <div className="form-group col-md-6">
                              <label>Bedrooms</label>
                              <select
                                disabled={submiting}
                                {...register("bedrooms", {
                                  required: "Bedrooms is required!",
                                })}
                                className={`form-control ${
                                  errors.bedrooms ? "border-danger" : ""
                                }`}
                              >
                                <option value="">
                                  Select Number of Bedrooms
                                </option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                              </select>
                              {errors.bedrooms && (
                                <p
                                  className="text-danger"
                                  style={{ fontSize: 14 }}
                                >
                                  {errors.bedrooms.message?.toString()}
                                </p>
                              )}
                            </div>

                            <div className="form-group col-md-6">
                              <label>Bathrooms</label>
                              <select
                                disabled={submiting}
                                {...register("bathrooms", {
                                  required: "Bathrooms is required!",
                                })}
                                id="bathrooms"
                                className={`form-control ${
                                  errors.bathrooms ? "border-danger" : ""
                                }`}
                              >
                                <option value="">
                                  Select Number of Bathrooms
                                </option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                              </select>
                              {errors.bathrooms && (
                                <p
                                  className="text-danger"
                                  style={{ fontSize: 14 }}
                                >
                                  {errors.bathrooms.message?.toString()}
                                </p>
                              )}
                            </div>
                            <div className="form-group col-md-6">
                              <label>
                                Year Built
                                
                              </label>
                              <input
                                type="text"
                                disabled={submiting}
                                placeholder="which year was the house built"
                                {...register("yearBuilt", {
                                  required: "Property Year Built is required!",
                                })}
                                className={`form-control ${
                                  errors.yearBuilt ? "border-danger" : ""
                                }`}
                              />
                              {errors.yearBuilt && (
                                <p
                                  className="text-danger"
                                  style={{ fontSize: 14 }}
                                >
                                  {errors.yearBuilt.message?.toString()}
                                </p>
                              )}
                            </div>
                            <div className="form-group col-md-6">
                              <label>
                                Square Feet
                                
                              </label>
                              <input
                                type="text"
                                disabled={submiting}
                                placeholder="Square Feet"
                                {...register("squareFeet", {
                                  required: "Property Square Feet is required!",
                                })}
                                className={`form-control ${
                                  errors.squareFeet ? "border-danger" : ""
                                }`}
                              />
                              {errors.squareFeet && (
                                <p
                                  className="text-danger"
                                  style={{ fontSize: 14 }}
                                >
                                  {errors.squareFeet.message?.toString()}
                                </p>
                              )}
                            </div>
                            <div className="form-group col-md-6">
                              <label>Number of Garages</label>
                              <input
                                type="text"
                                disabled={submiting}
                                placeholder="Number of Garages"
                                {...register("garages", {
                                  required:
                                    "Property Number of Garages is required!",
                                })}
                                className={`form-control ${
                                  errors.garages ? "border-danger" : ""
                                }`}
                              />
                              {errors.garages && (
                                <p
                                  className="text-danger"
                                  style={{ fontSize: 14 }}
                                >
                                  {errors.garages.message?.toString()}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <!-- Gallery --> */}
                      <div
                        className="frm_submit_block"
                      >
                        <h3>Gallery</h3>
                        <div className="frm_submit_wrap">
                        <DragAndDrop
                      heading={"Image Gallery"}
                      onChange={(e: any) => {
                        if (e.target.files && e.target.files[0]) {
                          for (let i = 0; i < e.target.files["length"]; i++) {
                            setFiles((prevState: any) => [
                              ...prevState,
                              e.target.files[i],
                            ]);
                          }
                        }
                      }}
                      setFiles={setFiles}
                      files={files}
                    />
                        </div>
                      </div>
                      {/* <!-- Location --> */}
                      <div className="frm_submit_block">
                        <h3>Location</h3>
                        <div className="frm_submit_wrap">
                          <div className="row">
                            <div className="form-group col-md-6">
                              <label>Address</label>
                              <input
                                type="text"
                                placeholder="Address"
                                disabled={submiting}
                                {...register("propertyAddress", {
                                  required: "Property Address is required!",
                                })}
                                className={`form-control ${
                                  errors.propertyAddress ? "border-danger" : ""
                                }`}
                              />
                              {errors.propertyAddress && (
                                <p
                                  className="text-danger"
                                  style={{ fontSize: 14 }}
                                >
                                  {errors.propertyAddress.message?.toString()}
                                </p>
                              )}
                            </div>

                            <div className="form-group col-md-6">
                              <label>City</label>
                              <input
                                type="text"
                                disabled={submiting}
                                placeholder="City"
                                {...register("propertyCity", {
                                  required: "Property City is required!",
                                })}
                                className={`form-control ${
                                  errors.propertyCity ? "border-danger" : ""
                                }`}
                              />
                              {errors.propertyCity && (
                                <p
                                  className="text-danger"
                                  style={{ fontSize: 14 }}
                                >
                                  {errors.propertyCity.message?.toString()}
                                </p>
                              )}
                            </div>

                            <div className="form-group col-md-6">
                              <label>State</label>
                              <input
                                type="text"
                                disabled={submiting}
                                placeholder="State"
                                {...register("propertyCountry", {
                                  required: "Property State is required!",
                                })}
                                className={`form-control ${
                                  errors.propertyCountry ? "border-danger" : ""
                                }`}
                              />
                              {errors.propertyCountry && (
                                <p
                                  className="text-danger"
                                  style={{ fontSize: 14 }}
                                >
                                  {errors.propertyCountry.message?.toString()}
                                </p>
                              )}
                            </div>

                            <div className="form-group col-md-6">
                              <label>Zip Code</label>
                              <input
                                type="text"
                                disabled={submiting}
                                placeholder="Enter Zip Code"
                                {...register("zipCode", {
                                  required: "Property Zip Code is required!",
                                })}
                                className={`form-control ${
                                  errors.zipCode ? "border-danger" : ""
                                }`}
                              />
                              {errors.propertyZipCode && (
                                <p
                                  className="text-danger"
                                  style={{ fontSize: 14 }}
                                >
                                  {errors.propertyZipCode.message?.toString()}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <!-- Detailed Information --> */}
                      <div className="frm_submit_block">
                        <h3>Detailed Information</h3>
                        <div className="frm_submit_wrap">
                          <div className="detailed-information">
                            <h3>Utilities</h3>
                            <div className="float-right pull-right">
                              <button
                                type="button"
                                onClick={handleAddField}
                                className="btn btn-sm btn-primary"
                              >
                                Add More <i className="fa fa-circle-plus"></i>{" "}
                              </button>
                            </div>
                            <div className="row">
                              {utilities.map((value, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="form-group col-md-4"
                                  >
                                    <label>Item {index + 1} </label>
                                    <input
                                      type="text"
                                      disabled={submiting}
                                      value={value.name}
                                      onChange={(e) =>
                                        handleUtilityChange(e, index)
                                      }
                                      className={`form-control`}
                                    />
                                    <div className="float-right">
                                      <button
                                        type="button"
                                        onClick={() =>
                                          handleRemoveUtility(index)
                                        }
                                        className="btn btn-sm btn-danger"
                                      >
                                        Remove{" "}
                                        <i className="fa fa-minus-plus"></i>{" "}
                                      </button>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                          {/* Interial Form */}
                          <div className="detailed-information">
                            <h3>Interial Details</h3>
                            <div className="float-right pull-right">
                              <button
                                type="button"
                                onClick={handleAddIndoorField}
                                className="btn btn-sm btn-primary"
                              >
                                Add More <i className="fa fa-circle-plus"></i>{" "}
                              </button>
                            </div>
                            <div className="row">
                              {interialDetails.map((value, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="form-group col-md-4"
                                  >
                                    <label>Item {index + 1} </label>
                                    <input
                                      type="text"
                                      disabled={submiting}
                                      value={value.name}
                                      onChange={(e) =>
                                        handleIndoorChange(e, index)
                                      }
                                      className={`form-control`}
                                      placeholder="interial Details eg. "
                                    />
                                    <div className="float-right">
                                      <button
                                        type="button"
                                        onClick={() =>
                                          handleRemoveIndoor(index)
                                        }
                                        className="btn btn-sm btn-danger"
                                      >
                                        Remove{" "}
                                        <i className="fa fa-minus-plus"></i>{" "}
                                      </button>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                          <div className="detailed-information">
                            <h3>OutDoor Details</h3>
                            <div className="float-right pull-right">
                              <button
                                type="button"
                                onClick={handleAddOutField}
                                className="btn btn-sm btn-primary"
                              >
                                Add More <i className="fa fa-circle-plus"></i>{" "}
                              </button>
                            </div>
                            <div className="row">
                              {outDoorDetails.map((value, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="form-group col-md-4"
                                  >
                                    <label>Item {index + 1} </label>
                                    <input
                                      type="text"
                                      disabled={submiting}
                                      value={value.name}
                                      onChange={(e) =>
                                        handleOutDoorChange(e, index)
                                      }
                                      className={`form-control`}
                                      placeholder="Out Door Details eg. swimming pool, parking, etc"
                                    />
                                    <div className="float-right">
                                      <button
                                        type="button"
                                        onClick={() =>
                                          handleRemoveOutDoor(index)
                                        }
                                        className="btn btn-sm btn-danger"
                                      >
                                        Remove{" "}
                                        <i className="fa fa-minus-plus"></i>{" "}
                                      </button>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                          {/* AMENITIES */}
                          <div className="detailed-information">
                            <h3>Property Amenities</h3>
                            <div className="float-right pull-right">
                              <button
                                type="button"
                                onClick={handleAddAmenitiesField}
                                className="btn btn-sm btn-primary"
                              >
                                Add More <i className="fa fa-circle-plus"></i>{" "}
                              </button>
                            </div>
                            <div className="row">
                              {amenities.map((value, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="form-group col-md-4"
                                  >
                                    <label>Item {index + 1} </label>
                                    <input
                                      type="text"
                                      value={value.name}
                                      disabled={submiting}
                                      onChange={(e) =>
                                        handleAmenitiesChange(e, index)
                                      }
                                      className={`form-control`}
                                      placeholder="Property Amenities eg. bike racks, lobby reception, fitness facility, etc"
                                    />
                                    <div className="float-right">
                                      <button
                                        type="button"
                                        onClick={() =>
                                          handleRemoveAmenities(index)
                                        }
                                        className="btn btn-sm btn-danger"
                                      >
                                        Remove{" "}
                                        <i className="fa fa-minus-plus"></i>{" "}
                                      </button>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                          <div className="row">
                            <div className="form-group col-md-12">
                              <label>Property Description</label>
                              <textarea
                                disabled={submiting}
                                placeholder="Property Description"
                                {...register("propertyDescription", {
                                  required: "Property Description is required!",
                                })}
                                className={`form-control h-120 ${
                                  errors.propertyDescription
                                    ? "border-danger"
                                    : ""
                                }`}
                              ></textarea>
                              {errors.propertyDescription && (
                                <p
                                  className="text-danger"
                                  style={{ fontSize: 14 }}
                                >
                                  {errors.propertyDescription.message?.toString()}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <!-- Contact Information --> */}
                      <div className="frm_submit_block">
                        <h3>Contact Information</h3>
                        <div className="frm_submit_wrap">
                          <div className="row">
                            <div className="form-group col-md-4">
                              <label>Name</label>
                              <input
                                type="text"
                                placeholder="property contact Name"
                                defaultValue={`${user?.first_name} ${user?.last_name}`}
                                disabled={submiting}
                                {...register("contactName", {
                                  required:
                                    "Property Contact Name Code is required!",
                                })}
                                className={`form-control ${
                                  errors.contactName ? "border-danger" : ""
                                }`}
                              />
                              {errors.contacName && (
                                <p
                                  className="text-danger"
                                  style={{ fontSize: 14 }}
                                >
                                  {errors.contacName.message?.toString()}
                                </p>
                              )}
                            </div>

                            <div className="form-group col-md-4">
                              <label>Email</label>
                              <input
                                type="email"
                                defaultValue={user?.email}
                                placeholder="property contact email"
                                disabled={submiting}
                                {...register("contactEmail", {
                                  required: "Property Zip Code is required!",
                                })}
                                className={`form-control ${
                                  errors.contactEmail ? "border-danger" : ""
                                }`}
                              />
                              {errors.email && (
                                <p
                                  className="text-danger"
                                  style={{ fontSize: 14 }}
                                >
                                  {errors.email.message?.toString()}
                                </p>
                              )}
                            </div>

                            <div className="form-group col-md-4">
                              <label>Phone </label>
                              <input
                                type="text"
                                disabled={submiting}
                                defaultValue={user?.phoneNumber}
                                placeholder="property Contact Number"
                                {...register("phoneNumber", {
                                  required:
                                    "Property Phone Number is required!",
                                })}
                                className={`form-control ${
                                  errors.phoneNumber ? "border-danger" : ""
                                }`}
                              />
                              {errors.phoneNumber && (
                                <p
                                  className="text-danger"
                                  style={{ fontSize: 14 }}
                                >
                                  {errors.phoneNumber.message?.toString()}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="form-group col-lg-12 col-md-12">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="gdpr"
                          />
                          <label className="form-check-label" htmlFor="gdpr">
                            I consent to having this website store my submitted
                            information so they can respond to my inquiry.
                          </label>
                        </div>
                      </div>

                      <div className="form-group col-lg-12 col-md-12">
                        <button
                          disabled={submiting}
                          className="btn btn-primary"
                          type="submit"
                        >
                          {submiting && (
                            <span>
                              <i className="fa fa-refresh fa-spin"></i> Please
                              Wait ...
                            </span>
                          )}
                          {!submiting && <span>Submit</span>}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            {/* <!-- row --> */}
          </div>
        </div>
      </section>
      {/* <!-- ============================ User Dashboard End ================================== --> */}
    </>
  );
};

export default HouseForm;
