import React from "react";
import { RequestsModel } from "../../../../models/requests_model";

interface IRequestProps {
  request: RequestsModel;
}

const RequestCard: React.FC<IRequestProps> = ({ request }) => {
  return (
    <>
      <div className="ground-client-single-list">
        <div className="ground-client-first">
          <div className="ground-client-thumb">
            <img
              style={{ width: "70px", height: "70px" }}
              src={`${process.env.REACT_APP_API_URL}/${request.property.image}`}
              className="img-f?.id rounded"
              alt=""
            />
          </div>
          <div style={{marginLeft: '10px'}} className="ground-client-caption">
            <h5 className="capitalize">{request.user.first_name} {request.user.last_name}</h5>
            <span className="text-mid">{request.user.email}</span>
            <h5>{request.property.propertyName}</h5>
          </div>
        </div>
        <div className="ground-client-last">
          <div className="ground-btn">
            <a
              href={`https://wa.me/+26${request.phoneNumber}`}
              className="btns bg-light-success text-success me-2">
              <i className="fa-brands fa-whatsapp"></i>
            </a>
            <a
              href={`mailto:${request.user.email}`}
              className="btns bg-light-info text-info">
              <i className="fa-solid fa-envelope"></i>
            </a>
          </div>
          <a
              href={`/accounts/dashboard/requests/request_details?request=${request.id}`}
              className="btn btn-sm btn-info text-white">
             Details <i className="fa-solid fa-arrow-right"></i>
            </a>
        </div>
      </div>
    </>
  );
};

export default RequestCard;
